
















































import { Component, Watch } from 'vue-property-decorator'
import _, { isArray } from 'lodash'
import __ from '@/helpers/__'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import http from '@/shared/helpers/http'
import { IFilter, ISearchUpdateData } from '@/shared/interfaces/form/form.interface'
import MultiSelect from 'vue-multiselect'

interface ItemData {
  value: string | number
  label: string | number
  details: string | null
  localItem: boolean
}

@Component({
  methods: { __ },
  components: { MultiSelect }
})
export default class SearchableField extends AbstractField {
  items: Object[] = []
  uuidItems: Object[] = []
  fullItemData: any = []
  localItem: any = []
  loading: boolean = false
  search: string = ''
  page: number = 1
  lastPage: number = 0
  initialLoad: boolean = false
  defaultPerPage: number = 999
  activeItemsOnly: boolean = this.field.activeItemsOnly

  @Watch('value') private onValueChange(): void {
    if ((this.value || this.value === 0) && !this.selectedValue) this.loadData()
  }

  get trackBy() {
    return this.field.multiple ? 'value' : null;
  }

  get selectedValue() {
    if (this.field.multiple) {
      return this.allItems.filter(item => {
        if (typeof this.value === 'string') {
          return this.value === (String(item.value))
        }
        if (!this.value) return false
        return (this.value.includes(item.value)) || this.value.includes(String(item.value))
      })
    } else {
      return _.find(this.allItems, (item: ItemData) => item.value === this.value)
    }
  }

  onClose() {
    this.search = ''
  }

  set selectedValue(newValue: any) {
    if (!newValue) {
      this.value = null
      return
    }

    if (this.field.multiple && isArray(newValue)) {
      this.value = newValue.map((item: ItemData) => item.value);
    } else {
      this.value = newValue.value;
    }
  }

  created() {
    if (this.field.loadItemsProps.perPage) {
      this.defaultPerPage = this.field.loadItemsProps.perPage
    }

    if ((this.value || this.value === 0)) {
      this.loadUuidData()
    }

    this.field.onCreated && this.field.onCreated(this.form, this.field.index, this.group, this.rowIndex)

    this.$root.$on('updateSearchField', (data: ISearchUpdateData) => {
      if (this.field.key === data.key) {
        this.loadData(data.label)
      }
    })
  }

  private debouncedSearch = this.field.internalSearch ? () => {} : _.debounce(this.loadData, 400)
  private valueWasJustSelected = false

  valueWasSelected(item:any): void {
    this.valueWasJustSelected = true
    setTimeout(() => {
      if (typeof this.field.onChange !== 'undefined') { this.field.onChange(item) }
    }, 100)
  }

  loadData(query: any = ''): void {
    if (this.valueWasJustSelected) {
      this.valueWasJustSelected = false

      return
    }

    this.loading = true
    this.page = 1
    this.search = query
    this.items = []
    if (this.field.vuexGetter) {
      this.pushData(
        this.$store.getters[this.field.vuexGetter].filter((item: any) => {
          if (!query) return item

          if (
            item[this.field.displayKey]
              .toString()
              .toLowerCase()
              .includes(query) ||
            item[this.field.valueKey]
              .toString()
              .toLowerCase()
              .includes(query)
          ) {
            return item
          }
        })
      )
      this.loading = false
      return
    }

    http
      .get(
        `${this.field.loadItemsProps.endpoint}?q=${query}&per_page=${this.field.noUuid ? 99999 : this.defaultPerPage}${
          this.filters
        }${this.sort}`
      )
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        if (typeof item.meta !== 'undefined') {
          this.lastPage = item.meta.last_page
        }
        if (this.activeItemsOnly) {
          item.data = _.filter(item.data, (entry) => !entry.disabled)
        }
        this.pushData(item.data)
        if (this.value) {
          this.selectedValue = _.find(this.allItems, (item: any) => item[this.field.selectKey] === this.value)
        }
        this.initialLoad = true
      })
      .finally((): void => {
        this.loading = false
      })
  }

  loadMoreData(): void {
    if (this.loading) return
    this.loading = true
    http
      .get(
        `${this.field.loadItemsProps.endpoint}?q=${this.search}&page=${++this.page}&per_page=${
          this.field.noUuid ? 99999 : this.defaultPerPage
        }${this.filters}${this.sort}`
      )
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.pushData(item.data, true)
        if (this.value) {
          this.selectedValue = _.find(this.allItems, (item: any) => item[this.field.selectKey] === this.value)
        }
      })
      .finally((): void => {
        this.loading = false
      })
  }

  loadUuidData(): void {
    if (this.field.vuexGetter) {
      this.loadData()
      return
    }
    this.loading = true
    http
      .get(`${this.field.alternativeGetEndpoint || this.field.loadItemsProps.endpoint}${this.uuidEndpoint}?per_page=${
          this.field.noUuid ? 99999 : this.defaultPerPage
      }`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        if (this.field.noUuid) this.initialLoad = true
        this.pushUuidData(this.field.noUuid ? item.data : [item.data])
        const foundValue = _.find(this.allItems, (item: any) => {
          return String(item[this.field.selectKey]) === String(this.value)
        })
        if (this.value && foundValue) {
          this.selectedValue = _.find(this.allItems, (item: any) => String(item[this.field.selectKey]) === String(this.value))
        }
        if (this.value && !foundValue && !this.field.multiple) {
          this.localItem = [
            this.returnItemType(
              {
                [this.field.displayKey]: this.value,
                [this.field.valueKey]: this.value
              },
              true
            )
          ]
        }
      })
      .finally((): void => {
        this.loading = false
      })
  }

  pushData(data: any, push: boolean = false): void {
    if (push) {
      this.items.push(...data.map((element: any) => this.returnItemType(element)))
      this.fullItemData.push(data)
      return
    }

    this.items = data.map((element: any) => this.returnItemType(element))
    this.fullItemData = data
  }

  pushUuidData(data: any): void {
    this.uuidItems = data.map((element: any) => this.returnItemType(element))
    this.fullItemData = data
  }

  onOpen() {
    if (!this.initialLoad) this.loadData()
  }

  returnItemType(data: any, localItem: boolean = false): ItemData {
    return {
      label: data[this.field.displayKey],
      value: data[this.field.valueKey],
      details: this.field.detailsKey ? data[this.field.detailsKey] || null : null,
      localItem
    }
  }

  get uuidEndpoint(): string {
    if (this.field.noUuid) return ''

    return `/${this.value}`
  }

  get allItems() {
    const localItem: any = this.returnItemType(
      {
        [this.field.displayKey]: this.search,
        [this.field.valueKey]: this.search
      },
      true
    )

    const resultArray = []
    if (this.field.allowToCreate && this.search) resultArray.push(localItem)

    return resultArray
      .concat(this.localItem)
      .concat(this.items)
      .concat(this.uuidItems)
  }

  get filters() {
    if (!this.field?.loadItemsProps?.filters) return ''

    return this.field.loadItemsProps.filters.map(
      (filter: IFilter) => `&filter[${filter.type}.${filter.name}]=${filter.value}`
    )
  }

  get sort() {
    if (!this.field?.loadItemSort) return ''

    return `&sort=${this.field?.loadItemSort}`
  }
}
